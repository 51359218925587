<template>
  <div class="col-lg-12 col-md-12 p-0">
    <LoadingOverlay v-if="isFetchingFilter" />
    <div v-else>
      <CRow class="mt-3">
        <CCol md="8">
          <CRow class="container col-12">
            <CCol class="p-0">
              <h4 class="text-primary my-3">
                <i class="fa-solid fa-clone text-primary cursor-pointer" 
                  @click="copyUrl()" 
                  v-c-tooltip="'Campaign URL'">
                </i>
                Basic Registration includes the following Mandatory Questions
                <!-- {{ getCampaignById.campaign_name }} - Campaign Form -->
              </h4>
            </CCol>
            <CCol
              class="d-flex justify-content-end align-items-center col-1 ml-3"
            >
              <div
                v-if="getScreenSize.width < 767"
                style="cursor: pointer"
                @click="goToHome()"
              >
                <i
                  class="fa-solid fa-house-chimney text-primary"
                  style="font-size: 24px"
                ></i>
              </div>
            </CCol>
          </CRow>
          <CCard>
            <CCardBody>
              <ValidationObserver ref="campaignForm" v-slot="{ handleSubmit }">
                <form
                  name="campaignForm"
                  @submit.prevent="handleSubmit(onSubmit)"
                >
                  <CRow v-for="(question, index) in questions" :key="index">
                    <CCol md="12" class="d-flex pl-0" v-if="!question?.generic">
                      <div class="mx-3">
                        <span class="d-flex align-items-baseline handle">
                          <span class="font-weight-bold text-muted text-nowrap"
                            >Field {{ index - 5 }}.</span
                          ></span
                        >
                        <div class="text-center">
                          <i
                            class="btn fa-solid fa-trash-can text-danger"
                            v-if="questions.length > 1 && !question.generic"
                            @click="openDeleteModal({ question, index })"
                          ></i>
                        </div>
                      </div>

                      <CRow class="w-100">
                        <CCol md="4" class="mb-2">
                          <CRow>
                            <label
                              class="col-lg-12 col-md-12 required"
                              :name="`lbl_type_${index}`"
                              >Type</label
                            >
                            <CCol md="12">
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <Select
                                  :name="`question_type_id_${index}`"
                                  :value="question.question_type_id"
                                  :options="
                                    options && options['question_type']
                                      ? options['question_type']
                                      : []
                                  "
                                  @change="handleChangeSelect"
                                  :multiple="false"
                                  :clearable="false"
                                  :error="errors[0]"
                                  :disabled="question.generic"
                                />
                              </ValidationProvider>
                            </CCol>
                          </CRow>
                        </CCol>
                        <CCol md="8" class="mb-2">
                          <CRow>
                            <label
                              class="col-lg-12 col-md-12 required"
                              :name="`lbl_qus_${index}`"
                              >Question</label
                            >
                            <CCol md="12">
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <TextInput
                                  :name="`field_name_${index}`"
                                  :value="question.field_name"
                                  @change="handleInput"
                                  :error="errors[0]"
                                  :disabled="question.generic"
                                />
                              </ValidationProvider>
                            </CCol>
                          </CRow>
                        </CCol>
                        <CCol md="4" class="mb-2 mt-auto">
                          <CRow>
                            <CCol md="8" sd="8">Mandatory</CCol>
                            <CCol md="4">
                              <CSwitch
                                :name="`is_mandatory_${index}`"
                                color="success"
                                :checked="question.is_mandatory"
                                shape="pill"
                                variant="3d"
                                @change.native="
                                  handleChangeCheck(
                                    `is_mandatory_${index}`,
                                    $event
                                  )
                                "
                                :disabled="question.generic"
                              />
                            </CCol>
                            <CCol md="12" class="d-flex align-items-center">
                              <span class="mr-3"></span>
                            </CCol>
                            <CCol md="12"> </CCol>
                          </CRow>
                        </CCol>
                        <CCol
                          md="8"
                          class="mb-2"
                          v-if="question.question_type_id"
                        >
                          <CRow
                            v-if="
                              [6, 7].includes(question.question_type_id.code)
                            "
                          >
                            <label class="col-lg-12 col-md-12 required"
                              >Value
                              <i
                                class="fa-solid fa-circle-info mx-1"
                                v-c-tooltip="{
                                  content:
                                    'Please type Comma or Enter key to confirm each of the values!',
                                }"
                              ></i
                            ></label>
                            <CCol md="12">
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <v-select
                                  :name="`option_${index}'`"
                                  :value="question.options"
                                  @input="
                                    (val) => handleChangeInput(val, index)
                                  "
                                  :taggable="true"
                                  :multiple="true"
                                  :clearable="true"
                                  :map-keydown="handlers"
                                  :clearSearchOnSelect="true"
                                  :components="{ OpenIndicator }"
                                  :disabled="question.generic"
                                >
                                  <template v-slot:no-options>
                                    <em style="opacity: 0.5"></em>
                                  </template>
                                </v-select>
                                <small class="has-error" v-if="errors[0]">{{
                                  errors[0]
                                }}</small>
                              </ValidationProvider>
                            </CCol>
                          </CRow>
                          <CRow v-if="question.question_type_id.code === 5">
                            <label class="col-lg-12 col-md-12 required"
                              >Max Value</label
                            >
                            <CCol md="12">
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <TextInput
                                  :name="`max_rating_count_${index}`"
                                  :value="question.max_rating_count"
                                  type="Number"
                                  @change="handleInput"
                                  :min="1"
                                  :onkeydown="restrictedKeys"
                                  :error="errors[0]"
                                  :disabled="question.generic"
                                />
                              </ValidationProvider>
                            </CCol>
                          </CRow>
                        </CCol>
                      </CRow>
                    </CCol>
                    <div class="d-flex w-100" v-if="!question?.generic || questions.length === index + 1">
                      <hr
                        :class="
                          questions.length === index + 1 ? 'w-45' : 'w-95'
                        "
                      />
                      <div>
                        <a
                          class="btn px-0 mx-1 text-success"
                          title="Add"
                          @click="addOneQuestion"
                          v-if="questions.length === index + 1"
                          ><i class="fa-solid fa-circle-plus fs-20"></i
                        ></a>
                      </div>
                      <hr :class="{ 'w-45': questions.length === index + 1 }" />
                    </div>
                  </CRow>
                </form>
              </ValidationObserver>
              <div class="d-flex justify-content-end">
                <CButton
                  type="button"
                  color="primary"
                  class="px-4 mr-3 btn-secondary"
                  to="/campaign-register"
                >
                  Cancel
                </CButton>
                <CButton
                  type="button"
                  color="primary"
                  class="px-4"
                  @click="onSubmit()"
                >
                  {{ isEdit ? "Update" : "Save" }}
                </CButton>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol md="4">
          <CRow class="container" justify="between">
            <CCol class="p-0">
              <h4 class="text-primary my-3">Preview</h4>
            </CCol>
            <CCol class="d-flex justify-content-end align-items-center">
              <div
                v-if="getScreenSize.width > 767"
                style="cursor: pointer"
                @click="goToHome()"
              >
                <i
                  class="fa-solid fa-house-chimney text-primary"
                  style="font-size: 24px"
                ></i>
              </div>
            </CCol>
          </CRow>
          <CCard>
            <CCardBody>
              <CRow
                v-for="(question, index) in questions"
                :key="index"
              >
                <CCol md="12"  class="mb-2" v-if="question.field_name && !question.generic">
                  <CRow>
                    <label
                      class="col-lg-12 col-md-12"
                      :class="{ required: question.is_mandatory }"
                    >
                      <span class="font-weight-bold"
                        >{{ index - 5 }}.{{ question.field_name }}</span
                      ></label
                    >
                    <CCol md="12" v-if="question.question_type_id">
                      <Select
                        v-if="[6, 7].includes(question.question_type_id.code)"
                        :name="`value_${index}`"
                        :options="question.options || []"
                        :multiple="question.question_type_id.code === 7"
                      />
                      <RadioButton
                        v-if="question.question_type_id.code === 1"
                        :name="`value_${index}`"
                        :options="
                          options && options['boolean']
                            ? options['boolean']
                            : []
                        "
                      />
                      <TextareaInput
                        v-if="question.question_type_id.code === 10"
                        :name="`value_${index}`"
                      />
                      <TextInput
                        v-if="question.question_type_id.code === 3"
                        :name="`value_${index}`"
                        type="number"
                      />
                      <TextInput
                        v-if="
                          [8, 9, 2].includes(question.question_type_id.code)
                        "
                        :name="`value_${index}`"
                      />
                      <Rating
                        v-if="question.question_type_id.code === 5"
                        :name="`value_${index}`"
                        :isNumberRating="true"
                        :maxRating="question.max_rating_count"
                        :value="0"
                      />
                      <DatePicker
                        v-if="question.question_type_id.code === 4"
                        :name="`value_${index}`"
                        format="DD-MM-YYYY"
                      />
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
    <Modal
      v-if="deleteConfirmModal.isShowPopup"
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deleteModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import DateInput from "@/components/reusable/Fields/DateInput";
import TimePicker from "@/components/reusable/Fields/TimePicker";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import CheckBox from "@/components/reusable/Fields/CheckBox";
import Rating from "@/components/reusable/Fields/Rating";
import PhoneInputwithCountry from "@/components/reusable/Fields/PhoneInputwithCountry";
import Modal from "@/components/reusable/Modal";
import { BASE_URL } from "@/service_urls";
import Vue from "vue";
import _ from "lodash";
export default {
  components: {
    Select,
    TextInput,
    PhoneInput,
    TextareaInput,
    DatePicker,
    DateInput,
    RadioButton,
    CheckBox,
    TimePicker,
    PhoneInputwithCountry,
    Rating,
    Modal,
  },
  data() {
    return {
      questions: [
        {
          is_mandatory: false,
        },
      ],
      deleteFields: [],
      payload: [],
      OpenIndicator: {
        render: (createElement) => null,
      },
      restrictedKeys:
        "javascript: return [8,9,46].includes(event.keyCode) ? true : !isNaN(Number(event.key))",
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "Confirmation",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
        index: null,
      },
      campaign: {},
    };
  },
  computed: {
    ...mapGetters([
      "questionTypes",
      "getCustomerId",
      "getOrgID",
      "getPathAcronymFromAccessToken",
      "getCampaignById",
      "isFetchingFilter",
      "getCampaignFields",
    ]),
    options() {
      return {
        question_type: this.questionTypes || [],
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
      };
    },
    isEdit() {
      return this.getCampaignFields.length || false;
    },
    campaignUUID() {
      const {
        params: { campaign_uuid },
      } = this.$route;
      if (campaign_uuid) {
        return campaign_uuid;
      }
      return null;
    },
    getScreenSize() {
      return {
        width: screen.availWidth,
        height: screen.availHeight,
      };
    },
    campaignID() {
      return this.getCampaignById.campaign_id;
    },
  },
  methods: {
    ...mapActions([
      "deleteCampaignField",
      "updateCampaignField",
      "showToast",
      "createCampaignField",
      "initCampaign",
      "fetchCampaignById",
      "fetchCampaignFields",
    ]),

    addOneQuestion() {
      this.questions.push({ is_mandatory: false });
    },
    handleChangeSelect(_name, value) {
      const { name, index } = this.decodeName(_name);
      Vue.set(this.questions[index], name, value);
      this.payload[index] = {
        ...this.payload[index],
        [name]: value?.code || value?.id || value,
      };
      if (value?.code === 5) {
        this.handleInput(`max_rating_count_${index}`, 5);
      }
    },
    handleInput(_name, value) {
      const { name, index } = this.decodeName(_name);
      Vue.set(this.questions[index], name, value);
      this.payload[index] = {
        ...this.payload[index],
        [name]: value,
      };
    },
    handleChangeInput(value, index) {
      const name = "options";
      Vue.set(this.questions[index], name, value);
      this.payload[index] = {
        ...this.payload[index],
        [name]: value,
      };
    },
    handleChangeCheck(_name, $event) {
      const { name, index } = this.decodeName(_name);
      const val = $event.target.checked;
      Vue.set(this.questions[index], name, val);
      this.payload[index] = {
        ...this.payload[index],
        [name]: val,
      };
    },
    handlers: (map, vm) => ({
      ...map,
      188: (e) => {
        e.preventDefault();
        vm.$nextTick(() => vm.$refs.search.focus());
        return vm.typeAheadSelect();
      },
    }),
    decodeName(data) {
      if (data) {
        const split_index = data.lastIndexOf("_");
        const name = data.substr(0, split_index);
        const index = data.substr(split_index + 1) || null;
        return { name, index };
      }
      return data;
    },
    openDeleteModal(data) {
      const { question, index } = data;

      this.deleteConfirmModal.modalContent = `Do you want to Delete Field - Field ${
        index + 1
      }. ${(question?.field_name && question?.field_name) || ""}?`;
      this.deleteConfirmModal.index = index;
      this.deleteConfirmModal.isShowPopup = true;
    },
    deleteModalCallBack(action) {
      if (action === "Confirm") {
        const { index } = this.deleteConfirmModal;
        this.deleteQuestion(index);
        this.deleteConfirmModal.isShowPopup = false;
        this.deleteConfirmModal.index = null;
      } else {
        this.deleteConfirmModal.isShowPopup = false;
        this.deleteConfirmModal.index = null;
      }
    },
    async deleteQuestion(index) {
      if (index > -1) {
        const { campaign_field_id } = this.payload[index];
        if (this.isEdit && campaign_field_id) {
          this.deleteCampaignField(campaign_field_id).then((res) => {
            if (res?.data) {
              this.removeQuestionByIndex(index);
            }
          });
        } else {
          this.removeQuestionByIndex(index);
        }
      }
    },
    removeQuestionByIndex(index) {
      if (this.questions[index]) this.questions.splice(index, 1);
      if (this.payload[index]) this.payload.splice(index, 1);
      return true;
    },
    async onSubmit() {
      const isValid = await this.$refs.campaignForm.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please fill mandatory fields!",
        });
        return;
      }
      const payload = this.payload.map((v) => {
        return {
          ...v,
          organisation_id: this.getOrgID,
          customer_uid: this.getCustomerId,
        };
      });

      let appendAction = [];
      const campaign_uuid = this.campaignUUID;
      const createPayload = this.payload.filter((v) => !v?.campaign_field_id);
      const updatePayload = this.payload.filter(
        (v) => v.campaign_field_id && !v.generic
      );

      if (createPayload.length) {
        appendAction = [
          ...appendAction,
          this.createCampaignField({
            campaign_id: this.campaignID,
            payload: createPayload,
          }),
        ];
      }

      if (updatePayload.length) {
        appendAction = [
          ...appendAction,
          this.updateCampaignField({
            campaign_id: this.campaignID,
            payload: updatePayload,
          }),
        ];
      }

      Promise.all(appendAction).then((res) => {
        this.populateData(campaign_uuid);
      });
    },
    populateData(campaign_uuid) {
      this.initCampaign(campaign_uuid).then(async (res) => {
        await this.fetchCampaignById(campaign_uuid);
        let campaign_id = this.getCampaignById.campaign_id;
        await this.fetchCampaignFields(campaign_id);
        if (this.getCampaignFields.length) {
          this.questions = this.getCampaignFields.map((v) => ({
            question_type_id: {
              code: v?.question_type?.question_type_id,
              label: _.startCase(v?.question_type?.question_type_name),
            },
            field_name: v?.field_name,
            is_mandatory: v?.is_mandatory,
            options: v?.options,
            max_rating_count: v?.max_rating_count,
            question_id: v?.question_id,
            generic: v?.generic,
          }));
          this.payload = this.getCampaignFields.map((v) => {
            return {
              question_type_id: v?.question_type?.question_type_id,
              field_name: v?.field_name,
              is_mandatory: v?.is_mandatory,
              options: v?.options,
              max_rating_count: v?.max_rating_count,
              campaign_field_id: v?.campaign_field_id,
              generic: v?.generic,
            };
          });
        }
      });
    },
    toogleHandleChangeCheck(name, $event) {
      const checked = $event.target.checked;
      Vue.set(this.campaign, name, checked);
    },
    goToHome() {
      this.$router.push("/campaign-register");
    },
    copyUrl() {
      let baseurl = BASE_URL;
      baseurl = "https://" + window.location.host;
      try {
        const url = `${baseurl}/${this.getPathAcronymFromAccessToken}/campaign/${this.campaignUUID}`;
        navigator.clipboard.writeText(url);
        this.showToast({
          class: "bg-success text-white",
          message: "URL Copied to Clipboard",
        });
      } catch (error) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Error copying URL to clipboard",
        });
      }
    },
  },
  mounted() {},
  async created() {
    const {
      params: { campaign_uuid },
    } = this.$route;
    if (campaign_uuid) {
      this.populateData(campaign_uuid);
    }
  },
};
</script>
<style>
hr {
  align-self: center;
  background: #eee;
  height: 1px;
}

.w-45 {
  width: 45%;
}
.w-95 {
  width: 95%;
}
</style>
